import Vue from "vue";
import App from "./App.vue";
import { createPinia, PiniaVuePlugin } from "pinia";
import "./assets/styles/style.css";
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { far } from "@fortawesome/free-regular-svg-icons";
import { fas } from "@fortawesome/free-solid-svg-icons";
import Vuecookie from "vue-cookies";
import VueHighlightJS from "vue-highlightjs";

library.add(far, fas);
Vue.use(PiniaVuePlugin);
Vue.use(Vuecookie);
Vue.use(VueHighlightJS);
const pinia = createPinia();

Vue.component("font-awesome-icon", FontAwesomeIcon);

new Vue({
  render: (h) => h(App),
  pinia,
}).$mount("#app");
