<template>
    <div class="grid grid-cols-2 text-sm font-normal px-5 text-gray-800 gap-20">
        <div class="flex flex-col justify-between items-center w-full p-4 row-start-1 gap-6">
            <h1
                class="w-full font-semibold text-2xl relative flex after:absolute after:content-('') after:w-full after:h-0.5 pb-4 after:bg-NeonBlue after:bottom-0 after:left-0">
                Camera setup
            </h1>
            <p
                class="w-full relative after:absolute after:content-('') after:w-full after:h-0.5 after:bg-gray-200 after:-bottom-6 after:left-0 pb-4">
                We use camera images to ensure fairness for everyone. Make sure that you are in front of your camera
            </p>
            <div ref="devices" class="flex flex-row justify-between items-center w-full relative mt-6">
                <p class="font-bold text-base">
                    <font-awesome-icon :icon="['fas', 'video']" class="mr-2" />Camera
                </p>
                <button @click="toggleDevices"
                    class="w-10 h-10 rounded-sm bg-gray-50 shadow-[0_0_3px_1px_rgba(0,0,0,20%)]">
                    <font-awesome-icon :icon="['fas', 'ellipsis-vertical']" />
                </button>
                <div v-if="camSwitcher"
                    class="absolute p-2 rounded-sm right-2 translate-y-full z-50 bottom-0 bg-white shadow-[0_0_3px_1px_rgba(0,0,0,20%)] w-fit">
                    <span v-for="cam in devices" :key="cam.deviceId" @click="changeDevice(cam.deviceId)"
                        class="flex flex-col cursor-pointer p-1 rounded-sm hover:bg-neutral-100">{{ cam.label }}</span>
                </div>
            </div>
            <WebcamComponent :setupWebcam="true" v-if="videoSuccess" />
            <div v-else class="flex flex-row justify-start items-center bg-[#F0D6BB] rounded gap-8 p-10">
                <font-awesome-icon :icon="['fas', 'triangle-exclamation']" class="text-4xl" />
                <p class="w-5/6 h-full">
                    It seems you don't have a camera or mic connected to your computer or they are blocked. To enable
                    the
                    camera and mic, click on the camera blocked icon in your browser's address bar and reload the page.
                    If
                    you don't enable a camera, you can still take the assessment, but then ksert cannot verify fair
                    play.
                </p>
            </div>
        </div>
        <div class="flex col-start-2 p-10 rounded bg-[#C8D7DE]">
            <p class="w-full h-full">
                <b>Trouble with your webcam?</b><br /><br />
                Ensure you have granted permission for your browser to access your camera. <br /><br />
                Ensure you are using a <b>supported browser</b>. <br /><br />
                If you have multiple camera devices, ensure you have given your browser and our website permission to
                use
                the right device. <br /><br />
                Try launching the assessment in incognito mode or in a private window. <br /><br />
                Ensure your camera drivers and web browser are up to date. <br /><br />
                Restart your device and try accessing the assessment again using the link in the invitation email.
                <br /><br />
            </p>
        </div>
    </div>
</template>

<script>
import { useAssessementStore } from "../store/assessements";
import WebcamComponent from "./WebcamComponent.vue";

export default {
    name: "CameraSetup",
    components: {
        WebcamComponent,
    },
    data() {
        return {
            devices: [],
            camSwitcher: false,
            videoSuccess: false,
        };
    },
    setup() {
        const assessementStore = useAssessementStore();
        return { assessementStore };
    },
    mounted() {
        this.getDevices();
    },
    methods: {
        async getDevices() {
            try {
                const devices = await navigator.mediaDevices.enumerateDevices();
                this.devices = devices.filter((d) => d.kind === "videoinput");
                this.assessementStore.selectedDevice = this.devices[0]?.deviceId;
                if (this.assessementStore.selectedDevice) {
                    this.videoSuccess = true;
                }
            } catch (err) {
                console.error("Error getting devices:", err);
            }
        },
        changeDevice(cam) {
            this.toggleDevices();
            this.assessementStore.selectedDevice = cam;
            this.videoSuccess = true;
        },
        toggleDevices() {
            this.camSwitcher = !this.camSwitcher;
            if (this.camSwitcher) {
                document.addEventListener("click", this.outsideClick);
            } else {
                document.removeEventListener("click", this.outsideClick);
            }
        },
        outsideClick(event) {
            if (!this.$refs.devices.contains(event.target)) {
                this.camSwitcher = false;
                document.removeEventListener("click", this.outsideClick);
            }
        },
    },
};
</script>


<style lang="scss" scoped>
.container-webcam {
    display: flex;
    justify-content: center;
    align-items: center;
}
</style>