<template>
    <div v-if="isLoading" class="modal">
        <div class="modal-content flex relative flex-col items-center justify-center gap-4 p-10 rounded-lg bg-[#fff]">
            <video src="../assets/animations/ai_cpu.webm" style="width: 200px; height: 200px;" autoplay loop muted
                playsinline></video>
            <p class="text-lg text-center">The AI is currently verifying your image.<br>This may take a few moments.</p>
        </div>
    </div>
</template>

<script>
export default {
    name: 'LoadingAI',
    props: {
        isLoading: Boolean
    },
    methods: {
        closeModal() {
            this.$emit('close');
        }
    }
}
</script>

<style scoped>
/* Modal styles */
.modal {
    display: flex;
    position: fixed;
    z-index: 1;
    left: 0;
    align-items: center;
    justify-content: center;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.4);
}

.modal-content {
    /* Additional styles can be added here */
}

.close {
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
}

.close:hover,
.close:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
}
</style>
