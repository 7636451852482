<template>
  <div class="nav">
    <div v-if="!assessmentStore.getPreparation" class="webcam">
      <WebcamComponent />
    </div>
    <img src="../assets/Images/go_logo.svg" width="180" height="100" />

    <span class="text-xl font-bold text-[#2196f3]"
      v-if="assessmentStore.showAssessmentNameInNavbar && assessmentStore.assessements[this.assessmentStore.test].category != 'Custom'">
      {{ this.assessmentStore.assessements[this.assessmentStore.test].name }}
    </span>

    <div class="flex flex-col items-start w-[15%]  justify-center gap-2">
      <div v-if="assessmentStore.assessements.length + 1 > assessmentStore.test &&
      assessmentStore.testStarted
      " class="flex gap-4 items-center w-full ">
        <span class="w-[15%] text-right text-base font-light">{{ qstProgress }}</span>
        <div class="w-[80%] h-fit rounded-full border bg-slate-200 p-0">
          <div :style="{ width: progressPercent + '%' }" class="rounded-full h-3 bg-[#2196ff]"></div>
        </div>
      </div>
      <div v-if="assessmentStore.assessements.length + 1 > assessmentStore.test &&
      assessmentStore.testStarted
      " class="w-full">
        <testTimer :duration="duration" :start="true" />
      </div>
    </div>

  </div>
</template>

<script>
import { useAssessementStore } from "@/store/assessements";
import WebcamComponent from "./WebcamComponent.vue";
import testTimer from "./timer.vue";
export default {
  name: "NavBAr",
  // props:['full_name', 'initials', 'logged'],
  components: { testTimer, WebcamComponent },
  setup() {
    const assessmentStore = useAssessementStore();
    return { assessmentStore };
  },
  computed: {
    capitalizeFirstLetter(text) {
      if (!text) return '';
      return text.charAt(0).toUpperCase() + text.slice(1);
    },
    qstProgress() {
      return (
        this.assessmentStore.question +
        "/" +
        this.assessmentStore.assessements[this.assessmentStore.test]
          .questions_list.length
      );
    },

    duration() {
      if (this.assessmentStore.assessements[this.assessmentStore.assessements.length - 1].category === 'Custom' && this.assessmentStore.test === this.assessmentStore.assessements.length - 1) {
        return !this.assessmentStore.question ? this.assessmentStore.durationQuestions[0] : this.assessmentStore.durationQuestions[this.assessmentStore.question - 1]
      }
      return this.assessmentStore.test > 0 ? this.assessmentStore.durationTests[this.assessmentStore.test] : this.assessmentStore.durationTests[0]
    },
    progressPercent() {
      return (
        (this.assessmentStore.question * 100) /
        this.assessmentStore.assessements[this.assessmentStore.test]
          .questions_list.length
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.webcam {
  display: none;
}

div.nav {
  display: flex;
  justify-content: space-between;
  background: #fff;
  font-family: "Roboto";
  font-style: normal;
  height: fit-content;
  align-items: center;
  padding: 1rem 2rem;

  // span {
  //     color: #2196f3;
  //     font-weight: 700;
  //     font-size: 22px;
  //     line-height: 33px;
  //     display: flex;
  //     align-items: center;
  //     gap: 0.5rem;

  //     &:hover {
  //         cursor: pointer;
  //     }
  // }

  nav {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-around;
    flex: 0 0 50%;

    a {
      font-weight: 420;
      line-height: 28px;
      text-decoration: none;
      color: #263238;
      font-size: 22px;
    }

    a:hover {
      color: #2196f3;
    }
  }

  .logged {
    a {
      width: 100%;
      display: flex;
      gap: 1rem;
      justify-content: flex-end;
      align-items: center;
      padding-right: 5%;
      text-transform: capitalize;

      span {
        border-radius: 50%;
        padding: 1%;
        background: #2196f3;
        color: #fff;
      }
    }
  }
}
</style>
