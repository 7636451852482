<template>
  <div
    class="w-full h-full flex flex-col justify-center items-center py-6 px-12"
  >
    <h1
      class="w-full text-left text-xl font-semibold relative after:content-('') after:absolute after:w-full after:h-0.5 after:left-0 after:bottom-0 mb-5 pb-4 after:bg-NeonBlue"
    >
      You have now finished the test
    </h1>
    <p class="font-light text-sm w-full mb-6">
      Help us improve these test by providing anonymous feedback. Your answer
      will only be available to GO platform, not your (prospective) employer.
      <br /><br />
      In your opinion, did the test accurately measure your skills?
    </p>
    <form
      class="w-full flex flex-row justify-between items-center gap-8 p-4 mb-6"
    >
      <div
        class="w-1/3 border border-slate-200 rounded-md px-3 py-4 flex flex-row justify-start items-center gap-3"
        :class="opinion === 'Yes' ? 'bg-NeonBlue text-white' : ''"
        @click="selectOpinion('Yes')"
      >
        <input
          type="radio"
          name="feedbackYes"
          id="feedbackYes"
          v-model="opinion"
          value="Yes"
          class="relative after:absolute after:content-('') after:top-0 after:right-0 after:border-2 after:rounded-full border border-slate-200 after:w-full after:h-full"
          :class="opinion === 'Yes' ? 'after:bg-NeonBlue' : 'after:bg-white'"
        />
        <label for="feedbackYes" class="font-light text-sm">Yes</label>
      </div>
      <div
        class="w-1/3 border border-slate-200 rounded-md px-3 py-4 flex flex-row justify-start items-center gap-3"
        :class="opinion === 'Somewhat' ? 'bg-NeonBlue text-white' : ''"
        @click="selectOpinion('Somewhat')"
      >
        <input
          type="radio"
          name="feedbackYes"
          id="feedbackSomewhat"
          v-model="opinion"
          value="Somewhat"
          class="relative after:absolute after:content-('') after:top-0 after:right-0 after:border-2 after:rounded-full border border-slate-200 after:w-full after:h-full"
          :class="
            opinion === 'Somewhat' ? 'after:bg-NeonBlue' : 'after:bg-white'
          "
        />
        <label for="feedbackSomewhat" class="font-light text-sm"
          >Somewhat</label
        >
      </div>
      <div
        class="w-1/3 border border-slate-200 rounded-md px-3 py-4 flex flex-row justify-start items-center gap-3"
        :class="opinion === 'No' ? 'bg-NeonBlue text-white' : ''"
        @click="selectOpinion('No')"
      >
        <input
          type="radio"
          name="feedbackYes"
          id="feedbackNo"
          v-model="opinion"
          value="No"
          class="relative after:absolute after:content-('') after:top-0 after:right-0 after:border-2 after:rounded-full border border-slate-200 after:w-full after:h-full"
          :class="opinion === 'No' ? 'after:bg-NeonBlue' : 'after:bg-white'"
        />
        <label for="feedbackNo" class="font-light text-sm">No</label>
      </div>
    </form>
    <textarea
      name="feedbackText"
      id="deefbackText"
      rows="10"
      placeholder="Please tell us more!"
      class="w-full border border-slate-200 rounded px-6 py-8 font-light text-base h-48 resize-none"
      v-model="feedBack"
      @input="handleFeedBack(feedBack)"
    ></textarea>
    <!-- <button class="w-36 px-5 py-3 ml-auto mt-16 text-white text-base font-bold rounded bg-NeonBlue ">Finish <font-awesome-icon :icon="['fas', 'angle-right']" /></button> -->
  </div>
</template>

<script>
export default {
  name: "FeedBack",
  data() {
    return {
      opinion: "",
      feedBack: "",
    };
  },
  props: {
    handleRating: Function,
    handleFeedBack: Function,
    addRating: Function,
  },
  watch: {
    opinion: function (val) {
      this.addRating(val);
    },
  },
  methods: {
    selectOpinion(text) {
      this.opinion = text;
    },
  },
};
</script>

<style lang="scss" scoped></style>
