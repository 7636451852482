<template>
  <div
    v-if="!custom"
    class="flex flex-row gap-10 text-left"
    v-show="current_qst - 1 === qst_number"
  >
    <div class="flex w-1/2 justify-between items-start flex-col h-full">
      <h1 class="w-full font-bold text-lg mb-4">Question</h1>
      <p
        class="w-full font-light text-sm"
        v-html="formatBold(capitalizeFirstLetter(qst_question))"
      ></p>
      <h1 v-if="qst_description" class="w-full font-bold text-lg my-4">
        Description
      </h1>
      <p
        class="text-sm text-left font-light"
        v-if="
          !qst_description?.toLowerCase().includes('png') &&
          !qst_description?.toLowerCase().includes('jpeg') &&
          !qst_description?.toLowerCase().includes('jpg')
        "
        v-html="formatBold(formatDescription(qst_description))"
      ></p>
      <img
        v-else
        :src="`https://server.go-platform.com/${qst_description}`"
        style="width: 700px; height: fit-content; margin: 0 auto"
        alt=""
      />
      <br />

      <br />
      <div class="code-container" v-if="qst_code">
        <!-- Display code using <pre> tag -->
        <pre
          v-highlightjs="qst_code"
          class="code-block"
        ><code class="language-javascript" ></code></pre>
      </div>
    </div>
    <div class="flex w-1/2 justify-between items-center flex-col h-full">
      <h1 class="w-full font-bold text-lg">Select only one answer</h1>
      <div
        v-if="qst_category !== 'essay'"
        class="w-full mt-10 flex flex-col justify-between items-center gap-4"
      >
        <QstOption
          v-for="(option, key, index) in qst_options"
          :optionKey="key"
          :selected="userAnswer == key"
          :key="option + '_' + index"
          :option-text="option"
          :SelectOption="handleAnswer"
          @answered="selectAnswer"
        />
      </div>
    </div>
  </div>
  <div
    v-else
    class="flex gap-10 text-left"
    :class="qst_category === 'Multiple-choice' ? 'flex-row' : 'flex-col'"
    v-show="current_qst - 1 === qst_number"
  >
    <div class="flex w-1/2 justify-between items-center flex-col h-full">
      <h1 class="w-full font-bold text-lg mb-8">Question</h1>
      <p
        class="text-sm font-light"
        v-html="formatBold(capitalizeFirstLetter(qst_question))"
      ></p>
      <p
        class="w-full font-light text-sm"
        v-html="formatBold(capitalizeFirstLetter(qst_question))"
      ></p>
    </div>
    <div
      v-if="qst_category === 'Multiple-choice'"
      class="flex w-1/2 justify-between items-center flex-col h-full"
    >
      <h1 class="w-full font-bold text-lg">Select only one answer</h1>
      <div
        class="w-full mt-10 flex flex-col justify-between items-center gap-4"
      >
        <QstOption
          v-for="(option, key, index) in qst_options"
          :optionKey="key"
          :selected="userAnswer == key"
          :key="option + '_' + index"
          :option-text="option"
          :SelectOption="handleAnswer"
          @answered="selectAnswer"
        />
      </div>
    </div>
    <div v-else class="w-full mt-4">
      <textarea
        name="customAnswer"
        @input="setAnswerChecked"
        v-model="userAnswer"
        @change="addCustomAnswer"
        id="customAnswer"
        placeholder="Type your answer here..."
        class="w-full h-60 bg-slate-100 border text-slate-700 text-sm font-light px-6 py-4 border-slate-300 rounded-md resize-y"
      ></textarea>
    </div>
  </div>
</template>

<script>
import QstOption from "./QstOption.vue";
import { useAssessementStore } from "@/store/assessements";
export default {
  name: "QstBox",
  components: {
    QstOption,
  },
  setup() {
    const assessementStore = useAssessementStore();
    return { assessementStore };
  },
  data() {
    return {
      userAnswer: "",
    };
  },
  props: {
    qst_number: { type: Number, required: true },
    qst_nbr_to_add: { type: Number, required: true },
    qst_options: {
      type: Object,
      required: true,
      default: () => ({}), // Fallback to an empty object
    },
    qst_question: { type: String, required: true },
    qst_title: { type: String, required: true },
    correct_answer: { type: String },
    qst_description: { type: String },
    qst_code: { type: String },
    current_qst: { type: Number, required: true },
    start: { type: Boolean, default: false, required: true },
    questions_nbr: { type: Number, required: true },
    testName: { type: String },
    custom: { type: Boolean, default: false },
    qst_category: { type: String, default: "" },
  },
  computed: {
    currentOptions() {
      return this.questions[this.step - 1].options;
    },
    formattedCode() {
      // Here you can apply any formatting or syntax highlighting to the code
      // For simplicity, let's just replace newline characters with <br> tags
      return this.questions[this.step - 1]?.code.replace(/\n/g, "<br>");
    },
  },
  methods: {
    formatBold(text) {
      if (!text) return "";
      // Replace **word** with <strong>word</strong>
      return text.replace(/\*\*(.*?)\*\*/g, "<strong>$1</strong>");
    },
    capitalizeFirstLetter(text) {
      if (!text) return "";
      return text.charAt(0).toUpperCase() + text.slice(1);
    },
    handleAnswer(key) {
      this.assessementStore.addAnswer(this.testName, this.qst_nbr_to_add, key);
    },
    selectAnswer(answer) {
      this.userAnswer = answer;
      this.assessementStore.checked = true;
    },
    setAnswerChecked() {
      if (!this.assessementStore.checked) this.assessementStore.checked = true;
    },
    addCustomAnswer() {
      this.assessementStore.answer.customAnswer.push({
        name: this.qst_title,
        question: this.qst_question,
        answer: this.userAnswer,
      });
    },
    formatDescription(text) {
      const points = text?.split(". ").map((point) => point.trim());
      const formattedText = points?.join(".<br><br>");

      return formattedText;
    },
  },
  watch: {
    qst_options(newVal, oldVal) {
      console.log("qst_options changed:", { newVal, oldVal });
    },
  },
};
</script>

<style lang="scss" scoped>
.code-container {
  background-color: #1e1e1e;
  /* VSCode dark background color */
  color: #d4d4d4;
  /* VSCode text color */
  padding: 20px;
  border-radius: 5px;
  width: 100%;
  overflow-x: scroll;
}

.code-block {
  font-family: "Fira Code", monospace;
  /* Use a monospace font for code */
  font-size: 14px;
  line-height: 1.5;
  margin: 0;
  overflow-x: auto;
  /* Allow horizontal scrolling for long lines */
  white-space: pre-wrap;
  /* Preserve whitespace and line breaks */
}

/* Syntax highlighting */
.code-block .keyword {
  color: #569cd6;
}

/* VSCode keyword color */
.code-block .string {
  color: #ce9178;
}

/* VSCode string color */
.code-block .comment {
  color: #6a9955;
}

/* VSCode comment color */
.code-block .function {
  color: #dcdcaa;
}
</style>
