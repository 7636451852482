<template>
  <div class=" flex gap-4 items-center text-base text-slate-700 font-light text-left w-full">
    <div class="w-[15%]" :class="(timeRemaining &&
                          parseInt(timeRemaining.split(':')[0]) === 0 &&
                          parseInt(timeRemaining.split(':')[1]) <= 10) ? 'red-text' : ''" v-if="!timeRemaining">
      {{ duration / 60 }}:00
    </div>
    <div class="w-[15%]" :class="(timeRemaining &&
                          parseInt(timeRemaining.split(':')[0]) === 0 &&
                          parseInt(timeRemaining.split(':')[1]) <= 10) ? 'red-text' : ''" v-else>
      {{ timeRemaining }}
    </div>
    <div class="w-[80%] h-3 rounded-full bg-gray-200">
      <div class="h-full bg-NeonBlue rounded-full w-0" :style="{ width: (timeRemainingInt / duration * 100) + '%', }">
      </div>
    </div>
  </div>
</template>

<script>
//import clockTimer from './clockTimer.vue'
import { useAssessementStore } from "@/store/assessements";

export default {
  name: "testTimer",
  props: {
    duration: { type: Number, required: true },
    start: { type: Boolean, default: false, required: true },
  },
  components: {},
  data() {
    return {
      // timeRemaining: this.assessementStore.timeRemaining,
      timerId: this.assessementStore.timerId,
      endTime: this.assessementStore.endTime,
    };
  },
  setup() {
    const assessementStore = useAssessementStore();
    return { assessementStore };
  },
  computed: {
    timeRemaining() {
      return this.assessementStore.timeRemaining;
    },
    timeRemainingInt() {
      const time = this.timeRemaining.split(':')
      const mins = parseInt(time[0])
      const secs = parseInt(time[1])

      return mins * 60 * 1000 + secs * 1000
    }
  },
  watch: {
    // start(newValue) {
    //   if (newValue) {
    //     console.log("Start variable has changed to true. Starting timer...");
    //     this.startTimer(); // Call the method to start the timer
    //   } else {
    //     console.log("Start variable has changed to false. Stopping timer...");
    //     this.stopTimer(); // Call the method to stop the timer
    //   }
    // },
  },

  // beforeDestroy() {
  //   clearInterval(this.timerId); // clear interval to avoid memory leaks
  // },
  methods: {
    // calculateTime() {
    //   const difference = this.endTime - Date.now();
    //   if (difference < 0) {
    //     clearInterval(this.timerId);
    //     this.timeRemaining = "Over!";
    //   } else {
    //     const seconds = Math.floor(difference / 1000);
    //     const minutes = Math.floor(seconds / 60);
    //     this.timeRemaining = `${minutes}:${seconds % 60 < 10 ? "0" : ""}${
    //       seconds % 60
    //     }`;
    //   }
    // },
    // startTimer() {
    //   this.endTime = Date.now() + this.timeDuration;
    //   this.timerId = setInterval(this.calculateTime, 1000);
    // },
    stopTimer() {
      clearInterval(this.timerId);
    },
  },
  mounted() {
    // console.log("--------mounted now ---------------------------", {
    //   start: this.start,
    // });
    if (this.start) {
      // this.assessementStore.startTimer();
    }
  },
};
</script>

<style lang="scss" scoped>
.timer {
  font-weight: 400;
  font-size: 22px;
  line-height: 33px;
  text-align: center;
  color: #30a46c;
}

.red-text {
  color: red;
  animation: scaleAnimation 1s infinite alternate !important;

  /* Adjust the duration and other properties as needed */
}

@keyframes scaleAnimation {
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(1.1);
  }
}
</style>
