<template>
  <div
    v-show="current_section === section_num"
    v-if="assessementStore.getPreparation && category === 'Custom'"
    class="mx-auto w-1/2 h-fit p-10 pb-12 gap-8 rounded-lg flex flex-col justify-between items-center"
  >
    <h1 class="font-bold text-lg w-full">Custom Questions</h1>
    <p
      class="font-light text-sm pb-8 mb-6 w-full text-left after:content-('') after:absolute after:bottom-0 after:left-0 after:bg-gray-200 after:w-full after:h-px relative"
    ></p>
    <h2 class="font-light text-sm w-full">
      The custom questions will start in
      <span class="font-bold">{{ assessementStore.preparation }}</span>
    </h2>
    <p class="w-full font-light text-sm">
      Please stay on this screen. The timer for your next test has
      <span class="font-bold">started and can not be paused</span> The timer
      continues even when you close the browser
    </p>
    <button
      @click="assessementStore.finishPreparation"
      class="w-36 px-5 py-3 ml-auto mt-16 mr-4 text-white text-base font-bold rounded bg-NeonBlue"
    >
      Start
    </button>
  </div>
  <div
    v-show="current_section === section_num"
    v-else-if="assessementStore.getPreparation && category != 'Custom'"
    class="mx-auto w-1/2 h-fit p-10 pb-12 gap-8 rounded-lg flex flex-col justify-between items-center"
  >
    <p class="font-light text-sm w-full text-left">This is the next test</p>
    <h1 class="font-bold text-lg w-full">{{ formattedTitle }}</h1>
    <p
      class="font-light text-sm pb-8 mb-6 w-full text-left after:content-('') after:absolute after:bottom-0 after:left-0 after:bg-gray-200 after:w-full after:h-px relative"
    ></p>
    <h2 class="font-light text-sm w-full">
      The next assessment will start in
      <span class="font-bold">{{ assessementStore.preparation }}</span>
    </h2>
    <p class="w-full font-light text-sm">
      Please stay on this screen. The timer for your next test has
      <span class="font-bold">started and can not be paused</span> The timer
      continues even when you close the browser
    </p>
    <button
      @click="assessementStore.finishPreparation"
      class="w-36 px-5 py-3 ml-auto mt-16 mr-4 text-white text-base font-bold rounded bg-NeonBlue"
    >
      Start
    </button>
  </div>
  <section
    v-else
    class="qstContainer flex flex-col items-center rounded-lg"
    v-show="current_section === section_num"
  >
    <!-- <div class="section_page"> -->
    <!--    <span>Section {{ section_num }}</span>
            <span>{{title}}</span>
            <span>{{ descripton }}</span> -->
    <!-- -->
    <!-- <h1>{{ formattedTitle }}</h1> -->
    <!-- </div> -->

    <div class="w-full h-fit">
      <slot></slot>
    </div>
    <!-- <button @click="startTimer()" class="next_btn">
            Next</button> -->
    <button
      @click="handleNext"
      :disabled="!this.assessementStore.checked"
      :style="{
        opacity: assessementStore.checked ? 1 : 0.8,
        cursor: assessementStore.checked ? 'pointer' : 'not-allowed',
      }"
      class="w-36 px-5 py-3 ml-auto mt-16 mr-4 text-white text-base font-bold rounded bg-NeonBlue"
    >
      Next
    </button>
  </section>
</template>

<script>
import { useAssessementStore } from "@/store/assessements";
export default {
  name: "testSection",
  props: {
    section_num: { type: Number },
    title: { type: String },
    descripton: { type: String },
    category: { type: String },
    current_section: { type: Number },
    start_qst: { type: Boolean, default: false },
    countD: { type: Boolean, default: false }, // Count down or not
  },
  components: {},
  setup() {
    const assessementStore = useAssessementStore();
    return { assessementStore };
  },
  data() {
    return {
      timerId: null,
    };
  },
  methods: {
    startTimer() {
      // this.assessementStore.nextQst()
      this.$emit("next");
    },
    handleNext() {
      this.$emit("next");
      this.assessementStore.checked = false;
    },
  },
  watch: {
    countD() {
      // this.startCountDown()
    },
  },
  computed: {
    formattedTitle() {
      // Capitalize the first letter of each word
      return this.title
        .split(" ")
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" ");
    },
  },
  mounted() {
    // this.startCountDown();
  },
};
</script>

<style lang="scss" scoped>
// .qstContainer {
//     display: flex;
//     flex-direction: column;
//     align-items: center;
// }

section {
  display: flex;
  justify-content: center;
  width: 100%;
  // padding: 3%;

  // >:nth-child(2) {
  //     width: 70%;
  //     display: flex;
  //     flex-direction: column;
  //     align-items: center;
  //     gap: 3rem;
  //     // padding-top: 3rem;
  // }
}

.section_page {
  display: flex;
  flex-direction: column;
  // margin: 3rem;
  // padding: 3%;
  align-items: center;
  width: 100%;
  // gap: 2rem;

  > :first-child {
    color: #2196f3;
    font-weight: 600;
    font-size: 40px;
    line-height: 60px;
    text-align: center;
  }

  > :nth-child(2) {
    font-weight: 600;
    font-size: 53px;
    line-height: 80px;
    text-align: center;
  }

  > :nth-child(3) {
    font-weight: 400;
    font-size: 24px;
    line-height: 36px;
    text-align: center;
    width: 75%;
  }
}

.next_btn {
  background: #2196f3;
  color: #fff;
  border-radius: 7px;
  border: none;
  padding: 1rem;
  font-weight: 600;
  font-size: 25px;
  line-height: 38px;
  width: 7em;
  margin-top: 3rem;
  position: absolute;
  bottom: 3%;
  cursor: pointer;
}

.next_btn:hover {
  opacity: 0.85;
}
</style>
