import { defineStore } from "pinia";
import axios from "axios";

export const useAssessementStore = defineStore("assessementStore", {
  state: () => ({
    isTalent: false,
    screenShots: [""],
    isCheater: false,
    capturedPhotos: [],
    face_detected: true,
    showAssessmentNameInNavbar: false,
    checked: false,
    allowFace: false,
    tabExited: false,
    fullScreenExited: false,
    mouseExited: false,
    exitedCount: 0,
    selectedDevice: null,
    projectId: "",
    avatar_url: "",
    first_name: "",
    second_name: "",
    candidate_id: "",
    status: "",
    userEmail: "",
    testStarted: false,
    userTimer: 0,
    startTime: 0,
    // candidateTime: 0,
    endTime: 0,
    assessements: [],
    question: 0,
    disabled: false,
    durationTests: [],
    durationQuestions: [],
    test: 0,
    questionTime: 0,
    timeRemaining: 0,
    answer: {
      projectId: "",
      assessmentId: "",
      candidate: "",
      candidateTime: 0,
      answers: {},
      customAnswer: [],
      traits: {},
    },
    customAnswer: {},
    isLogged: false,
    fullName: "",
    english: "",
    disability: "",
    preparation: 15,
    newTalent: {},
    screeners: [],
  }),
  getters: {
    getAll: (state) => state.assessements,
    metaInfos: (state) => {
      return state.assessements.map((el, id) => {
        return {
          [el.name]: {
            name: el.name,
            qstCount: el.questions_list.length,
            index: id,
          },
        };
      });
    },

    getInitials: (state) => {
      const name = state.fullName?.split(" ");
      return (
        (name[0]?.charAt(0) ?? "") + (name[1]?.charAt(0) ?? "")
      ).toUpperCase();
    },
    getUserTimer: (state) => state.userTimer,
    getPreparation: (state) => (state.preparation > 0 ? true : false),
  },
  actions: {
    addCapturedPhoto(cheater_id) {
      axios
        .post(
          `https://server.go-platform.com/anticheat/create-photo/${cheater_id}`,
          { photos: this.capturedPhotos }, // Send the array of photos
          { withCredentials: true }
        )
        .then(() => {
          console.log("Photos successfully added");
        })
        .catch((error) => {
          console.log(error);
        });
    },
    createCheater() {
      let cheater_id = "";
      // console.log("candidate ID", this.assessementStore.candidate_id);
      axios
        .post(
          `https://server.go-platform.com/anticheat/create-cheater/${this.candidate_id}`,
          null,
          { withCredentials: true }
        )
        .then((response) => (cheater_id = response.data._id))
        .then(() => {
          if (this.capturedPhotos) {
            this.addCapturedPhoto(cheater_id);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getAvatar() {
      axios
        .post(
          "https://server.go-platform.com/candidates/get-avatar",
          { candidateEmail: this.userEmail },
          { withCredentials: true }
        )
        .then((response) => {
          this.avatar_url = response.data.avatar;
          this.first_name = response.data.first_name;
          this.second_name = response.data.second_name;
          this.candidate_id = response.data.candidateId;
          this.status = response.data.status;
          // this.isLoading = false;
        })
        .catch((error) => {
          console.log(error);
          // this.isLoading = false;
        });
    },
    countDown() {
      const timerID = setInterval(() => {
        if (this.preparation > 0) {
          this.preparation--;
        } else {
          clearInterval(timerID);
          this.finishPreparation();
        }
      }, 1000);
    },
    resetPreparation() {
      console.log(
        "resetPreparation=------------------------===================-------------------"
      );
      this.preparation = 15;
      // clearInterval(id)
    },
    finishPreparation() {
      this.preparation = 0;
      console.log("TEST NUMBER: " + this.test);
      if (
        this.assessements[this.assessements.length - 1].category !== "Custom"
      ) {
        this.questionTime =
          this.test > 0 ? this.durationTests[this.test] : this.durationTests[0];
      } else if (
        this.assessements[this.assessements.length - 1].category === "Custom" &&
        this.test === this.assessements.length - 1
      ) {
        this.questionTime = this.durationQuestions[0];
      }
      this.showAssessmentNameInNavbar = true;
      this.calculateTimePassed();
      this.startTimer();
      this.testStarted = true;
      console.log(
        "TEST NUMBER: " + this.test,
        "Show Assessment Name in Navigation" + this.showAssessmentNameInNavbar
      );
    },

    async fetchAssessments() {
      let config = {
        method: "get",
        maxBodyLength: Infinity,
        url: "https://server.go-platform.com/AssessmentTest/candidatesAssessments",
        headers: {},
        withCredentials: true,
      };

      await axios
        .request(config)
        .then((response) => {
          console.log("Here is the response", response.data);
          this.assessements = response.data.assessments;
          this.projectId = response.data.projectId;
          this.userEmail = response.data.email;
          this.screeners = response.data.screeners;
          this.isTalent = response.data.isTalent ? true : false;

          if (this.isTalent) {
            console.log("STARTING..........Talent Test");
            this.getAvatar();
            this.answer.assessmentId = response.data.assessmentId;
            this.answer.candidate = response.data.email;
            // this.resetPreparation();
            // this.countDown();
          }
          this.calculateTime();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    requestFullScreenOnClick() {
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen().catch((error) => {
          console.error("Error entering fullscreen mode:", error);
        });
      }
    },
    nextQst() {
      if (
        this.assessements[this.assessements.length - 1].category === "Custom" &&
        this.test === this.assessements.length - 1
      ) {
        this.question++;
        this.questionTime = this.durationQuestions[this.question - 1];
        this.calculateTimePassed();
        this.startTimer();
        console.log(
          "Here is the question NUMBER",
          this.question,
          "Hhere is the question time",
          this.questionTime
        );
      } else {
        this.question++;
      }
      this.disabled = false;
      return this.question;
    },
    resetQst() {
      this.question = 1;
      return this.question;
    },
    nextTest() {
      this.test++;
      this.resetPreparation();
      this.countDown();
      return this.test;
    },
    addTests(test) {
      this.answer.answers[test] = {};
      this.answer.traits[test] = {};
    },
    addAnswer(test, number, value) {
      const currentAssessment = this.assessements.filter(
        (a) => a.name === test
      );
      this.answer.traits[test][number] =
        currentAssessment[0].questions_list[number - 1]?.trait;
      this.answer.answers[test][number] = value;
    },
    setUser(email) {
      this.answer.candidate = email;
      this.answer.projectId = this.projectId;
    },
    setFullName(firstName, lastName) {
      this.fullName = (firstName + " " + lastName).toLowerCase();
    },
    userLogged() {
      this.isLogged = true;
    },
    // calculateTime() {
    //   this.assessements.map((assessment) => {
    //     this.userTimer += assessment.questions_list.length * 35000;
    //     console.log({ timeeeeeeeeeerrrrr: this.userTimer });
    //   });
    //   this.endTime = Date.now() + this.userTimer;
    //   console.log({ endTime: this.endTime });
    // },
    startTimer() {
      this.disabled = false;
      this.startTime = Date.now();
      this.endTime = Date.now() + this.questionTime;
      this.timerId = setInterval(this.calculateTime, 1000);
    },
    calculateTime() {
      const difference = this.endTime - Date.now();
      if (difference < 0) {
        clearInterval(this.timerId);
        this.timeRemaining = "Over!";
        // setTimeout(() => {
        this.disabled = true;
        // this.nextQst();
        // }, 2000);
      } else {
        const seconds = Math.floor(difference / 1000);
        const minutes = Math.floor(seconds / 60);
        this.timeRemaining = `${minutes}:${seconds % 60 < 10 ? "0" : ""}${
          seconds % 60
        }`;
      }
    },
    calculateTimePassed() {
      const now = Date.now();
      if (this.startTime) {
        const difference = parseInt(now - this.startTime);
        this.answer.candidateTime += difference;
        // const passedSeconds = Math.floor(difference / 1000);
        // const passedMinutes = Math.floor(passedSeconds / 60);
        // const remainingSeconds = passedSeconds % 60;
      }
    },
    SelectOption(value = "english", choice) {
      if (value == "english") this.english = choice;
      else this.disability = choice;
    },
    fillTalent(talent) {
      this.newTalent = { email: this.userEmail, ...talent };
    },
    submitNewTalent(clearFields) {
      let config = {
        method: "put",
        maxBodyLength: Infinity,
        headers: {},
        withCredentials: true,
      };
      axios
        .put(
          "https://server.go-platform.com/candidates/updateCandidate",
          this.newTalent,
          config
        )
        .then(() => {
          clearFields();
        })
        .catch((err) => console.error(err));
    },
  },
});
