import { render, staticRenderFns } from "./assessementTest.vue?vue&type=template&id=554a59ed&scoped=true"
import script from "./assessementTest.vue?vue&type=script&lang=js"
export * from "./assessementTest.vue?vue&type=script&lang=js"
import style0 from "./assessementTest.vue?vue&type=style&index=0&id=554a59ed&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "554a59ed",
  null
  
)

export default component.exports