<template>
  <div class="toast" v-if="isVisible">
    <div class="toast-content" :style="{ backgroundColor: bgColor }">
      {{ message }}
    </div>
  </div>
</template>

<script>
export default {
  name: "ToastNotification",
  props: {
    message: String,
    isVisible: Boolean, // Renamed the prop to isVisible
    bgColor: String,
  },
  data() {
    return {
      showToast: this.isVisible,
    };
  },
  methods: {
    hideToast() {
      this.showToast = false;
    },
  },
  watch: {
    isVisible: function (newVal) {
      if (newVal) {
        this.showToast = true;
        setTimeout(this.hideToast, 5000); // Auto-hide after 5 seconds
      }
    },
  },
};
</script>

<style scoped>
.toast {
  position: fixed;
  top: 20px;
  right: 20px;
}

.toast-content {
  padding: 10px;
  border-radius: 4px;
  color: white;
  font-size: 18px;
  font-weight: 600;
}
/* Style for the toast background color */
</style>
