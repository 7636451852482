<template>
    <div class="flex flex-row justify-between items-center h-full gap-14 font-light text-neutral-800 mb-5">
        <div class="w-1/2 h-full">
            <h1 class="text-xl font-medium">Hello <b>{{ store.fullName }},</b></h1>
            <p class="text-sm font-medium mt-8 leading-[28px]">
                Thank you for applying and welcome to our assessment. <br><br>
                Completing it will give you a chance to show off your skills and stand out from the crowd!
                <br><br> Good luck!
            </p>
        </div>
        
        <div class="w-1/2 h-full">
            <h1 class="text-xl font-bold">A few things before you start:</h1>
            <p class="text-sm pl-3 mt-8">
                <ul class="pl-4 list-disc ">
                    <li v-for="rule in rules" :key="rule" class="my-4 leading-[20px]" >{{ rule }}</li>
                </ul>
            </p>
        </div>
        
    </div>
</template>

<script>
import { useAssessementStore } from '@/store/assessements';
export default {
    name:"GuidesRules",
    data(){
        return{
            
            testsNum:0
        }
    },
    computed:{
        rules(){
            const duration= this.store.assessements.reduce((prev, curr)=>{
                return curr.duration? prev+curr?.duration : prev+10
            },0)
            return [
                `This assessment consists of ${this.store.assessements.length} tests. It will take approximately ${duration} minutes to complete.`,
                "The assessment is timed. A timer is shown per test or per question.",
                "Before each test there may be preview questions, be aware the timer for the test will have already started.",
                "Please allow the use of your camera/webcam and do not leave full-screen mode. Snapshots will be taken of you periodically during the assessment. These measures are taken to ensure fairness for everyone.",
                "Turn on your speakers or headphones (to play audio).",
                "You are free to use a calculator, pen and paper.",
                "We recommend completing the assessment in one go."
            ]
        },
    },
    setup () {
        const store =useAssessementStore()
        return {store}
    }
}
</script>

<style lang="scss" scoped>

</style>