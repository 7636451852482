<template>
    <div v-if="isVisible" class="modal">
      <div class="modal-content flex relative flex-col items-center justify-center gap-2 p-10 rounded bg-[#C8D7DE]">
        <img src="../assets/Images/face_id_check.png" class="w-60 h-60" alt="">
          <p class="text-lg text-center">Please upload a photo with a <br> <strong>clear face visible</strong>.</p>
          <span class="close absolute top-3 mb-0 right-5" @click="closeModal">&times;</span>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'ImageModal',
    props: {
      isVisible: Boolean
    },
    methods: {
      closeModal() {
        this.$emit('close');
      }
    }
  }
  </script>
  
  <style scoped>
  /* Modal styles */
  .modal {
    display: flex; /* Hidden by default */
    position: fixed; /* Stay in place */
    z-index: 1; /* Sit on top */
    left: 0;
    align-items: center;
    justify-content: center;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto; /* Enable scroll if needed */
    background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
  }
  
  .modal-content {
    border: 1px solid #888;
  }
  
  .close {
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
  }
  
  .close:hover,
  .close:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
  }
  </style>
  