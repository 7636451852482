<template>
  <div
    class="h-fit p-10 pb-12 bg-white rounded-lg flex flex-col justify-between items-center shadow-[0_0_15px_0_rgba(0,0,0,15%)] w-full"
    v-if="this.assessementStore.screeners.length > 0"
  >
    <div class="w-full">
      <div class="flex justify-between">
        <h1 class="text-[16px] text-slate-700 font-md">
          {{ this.assessementStore.screeners[this.qstStep - 1]?.question }}
          <span
            class="text-[12px] text-slate-500 font-large"
            v-if="
              this.assessementStore.screeners[this.qstStep - 1]?.type ===
              'multiple'
            "
            >( Multiple choice )</span
          >
        </h1>
      </div>
      <div class="mt-8">
        <div
          v-if="
            this.assessementStore.screeners[this.qstStep - 1].type === 'single'
          "
          :class="{
            'grid grid-cols-2 gap-4': true,
          }"
        >
          <div
            v-for="(option, index) in this.assessementStore.screeners[
              this.qstStep - 1
            ]?.options"
            :key="index"
            :class="{
              'radio-container': true,
              checked: selectedOption == option.answer,
            }"
            class="relative radio-container"
            @click="selectRadio(option)"
          >
            <div>
              <input
                type="radio"
                :id="option.answer"
                :value="option.answer"
                v-model="selectedOption"
                name="option"
                class="custom-radio"
              />
              <label :for="option.answer" class="mx-5">{{
                option.answer
              }}</label>
            </div>
          </div>
        </div>
        <div
          v-else-if="
            this.assessementStore.screeners[this.qstStep - 1]?.type ===
            'multiple'
          "
          :class="{
            'grid grid-cols-2 gap-4': true,
          }"
        >
          <div
            v-for="(option, index) in this.assessementStore.screeners[
              this.qstStep - 1
            ].options"
            :key="index"
            class="flex items-center gap-2"
            :class="{
              'radio-container': true,
              checked: multiOption.includes(option.answer),
            }"
            @click="selectRadio(option)"
          >
            <div class="flex gap-4">
              <input
                type="checkbox"
                :id="option.answer"
                :value="option.answer"
                name="option"
                class="custom-checkbox"
                :checked="multiOption.includes(option.answer)"
              />
              <label>{{ option.answer }}</label>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="w-full flex justify-end">
      <button
        v-if="isBtnLoading"
        class="w-36 h-16 px-5 py-2 ml-auto mt-16 text-white text-base font-bold rounded bg-NeonBlue flex items-center justify-center"
        :class="
          disableBtn
            ? 'cursor-not-allowed bg-blue-300'
            : 'cursor-pointer bg-NeonBlue '
        "
        :disabled="disableBtn"
      >
        <LoaderComponent />
      </button>
      <button
        v-else
        @click="handleClick"
        class="w-36 h-16 px-5 py-2 ml-auto mt-16 text-white text-base font-bold rounded bg-NeonBlue"
        :class="
          disableBtn
            ? 'cursor-not-allowed bg-blue-300'
            : 'cursor-pointer bg-NeonBlue '
        "
        :disabled="disableBtn"
      >
        {{ buttonText }}
      </button>
    </div>
  </div>
</template>

<script>
import { useAssessementStore } from "@/store/assessements";
import LoaderComponent from "../LoaderComponent.vue";
import axios from "axios";
export default {
  name: "ScreenersContainer",
  components: {
    LoaderComponent,
  },
  props: {
    nextSection: { type: Function },
    nextStep: { type: Function },
  },
  setup() {
    const assessementStore = useAssessementStore();
    return { assessementStore };
  },
  data() {
    return {
      screeners: this.assessementStore.screeners,
      totalSteps: 0,
      qstStep: 1,
      isBtnLoading: false,
      disableBtn: false,
      buttonText: "Next",
      selectedOption: "",
      multiOption: [],
      question: this.assessementStore.screeners[this.qstStep - 1],
      answers: [],
    };
  },
  watch: {
    "assessementStore.screeners": function (val) {
      this.screeners = val; // Update local `screeners` if needed
      this.totalSteps = this.screeners.length; // Update `totalSteps` based on new length
      console.log({ updatedScreeners: val });
    },
  },
  methods: {
    handleClick() {
      this.answers.push({
        question: this.qstStep - 1,
        answer:
          this.assessementStore.screeners[this.qstStep - 1].type == "single"
            ? this.selectedOption
            : this.multiOption,
      });
      if (this.qstStep < this.totalSteps) {
        this.qstStep++;
        return;
      } else {
        this.isBtnLoading = true;
        this.disableBtn = true;
        // setTimeout(() => {
        //   this.isBtnLoading = false;
        // }, 2000);
        this.postScreeners();
      }
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
    selectRadio(optionValue) {
      console.log({ optionValue });
      let option = optionValue.answer;
      // When an option is clicked, update the selectedOption value
      if (this.assessementStore.screeners[this.qstStep - 1].type == "single") {
        this.selectedOption = option;
        console.log(this.selectedOption, "selectedOption");
      } else {
        // If the question type is multiple, toggle the selected option
        if (this.multiOption.includes(option)) {
          this.multiOption = this.multiOption.filter((opt) => opt !== option);
          console.log(this.multiOption, "multiOption");
        } else {
          this.multiOption.push(option);
          console.log(this.multiOption, "multiOption");
        }
      }
    },
    async postScreeners() {
      await axios
        .post(
          "https://server.go-platform.com/AssessmentTest/submitScreeners",
          {
            answers: this.answers,
          },
          {
            withCredentials: true,
          }
        )
        .then((response) => {
          console.log(response);
          if (response.data.passed == true) {
            this.isBtnLoading = false;
            this.disableBtn = false;
            this.nextSection();
          } else {
            this.isBtnLoading = false;
            this.disableBtn = false;
            window.location.reload();
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  mounted() {
    this.totalSteps = this.screeners.length;
    console.log({ screeners: this.screeners });
    if (this.screeners.length == 0) {
      this.nextSection();
    }
  },
};
</script>

<style scoped lang="scss">
.radio-container {
  margin-top: 1%;
  margin-bottom: 10px;
  /* Adjust margin as needed */
  cursor: pointer;
  /* Change cursor to pointer */
  border: 1px solid #ccc;
  /* Border color */
  padding: 16px 16px;
  /* Adjust padding as needed */
  border-radius: 5px;
  transition: all 0.3s ease-in-out;
}

/* Hover effect */
.radio-container:hover {
  background-color: #f0f0f0;
  /* Background color on hover */
}

/* Style for checked radio button container */
.checked {
  background-color: #2196f3;
  /* Background color when checked */
  color: #fff;
  /* Text color when checked */
  border-color: #2196f3;

  /* Border color when checked */
  &:hover {
    background-color: #2196f3;
    /* Background color when checked */
  }
}

.custom-radio {
  /* Hide the default radio button */
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  /* Create a custom circular radio button */
  width: 25px;
  height: 25px;
  border: 2px solid #ccc;
  border-radius: 50%;
  outline: none;
  /* Position the radio button relative to its container */
  position: relative;
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
}

.custom-checkbox {
  /* Hide the default checkbox */
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  /* Create a custom square checkbox */
  width: 25px;
  height: 25px;
  border: 2px solid #ccc;
  border-radius: 0.25rem;
  /* 4px */
  outline: none;
  /* Position the checkbox relative to its container */
  position: relative;
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
}

.custom-checkbox:checked {
  /* Change border color when checked */
  border-color: #fff;

  &::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 12px;
    height: 12px;
    background-color: #fff;
    border-radius: 50%;
  }
}

.custom-radio:checked {
  /* Change border color when checked */
  border-color: #fff;

  /* Add inner circle when checked */
  &::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 12px;
    height: 12px;
    background-color: #fff;
    border-radius: 50%;
  }
}
</style>
