<template>
    <section>
          <span style="color: black; text-align: left">{{
            jobPosition
          }}</span>
          <span style="color: grey; margin-bottom: 15px">{{ key2 }}</span>
          <p style="margin-bottom: 15px">
            Hi <strong>{{ key1 }}</strong>
          </p>
          <p class="intructions">
            Congratulations, your application with
            <strong>{{ company }}</strong> is progressing!
          </p>
          <p class="intructions">
            We do things a little differently here so in this stage, you'll
            complete the <strong>{{ jobPosition }}</strong> assessment that
            will give you the opportunity to tell us a bit more about yourself
            and showcase your talent.
          </p>
          <p class="intructions">
            In this online stage, you'll be asked a series of interactive
            questions that may require answers in several formats like multiple
            choice, video, and text. This enables us to find out a little more
            about you and see how you approach certain scenarios.
          </p>
          <p class="intructions">
            It's also a great way for you to see what it'll be like working with
            us as the questions are related to the day-to-day tasks you'll be
            doing on the job.
          </p>
          <p class="intructions">
            Please complete this at a time and place that works for you. And
            make sure you have the ability to record video on the device you
            choose to use.
          </p>
          <p class="intructions">
            We look forward to finding out more about you.
          </p>
          <p class="intructions">Good luck!</p>
          <div style="width: 100%; margin-bottom: 40px">
            <div
              style="
                border: 1px solid whitesmoke;
                border-top-left-radius: 3px;
                border-top-right-radius: 3px;
                padding: 18px 35px;
                font-weight: 600;
              "
            >
              <span>
                <font-awesome-icon
                  :icon="['far', 'clock']"
                  class="mx-2"
                  style="color: grey"
                />
                SUBMISSION DEADLINE IS {{ key3 }} <br /><span
                  style="
                    padding-left: 37px;
                    color: grey;
                    font-weight: unset;
                    font-size: 14px;
                  "
                  >FYI that means {{ key4 }}</span
                ></span
              >
              <br />
              <br />
              <span>
                <font-awesome-icon
                  :icon="['far', 'file']"
                  class="mx-2"
                  style="color: grey"
                />
                {{ key5 }} ASSESMENTS</span
              >
            </div>
            <div
              style="
                border: 1px solid whitesmoke;
                border-bottom-right-radius: 3px;
                border-bottom-left-radius: 3px;
                padding: 18px 35px;
              "
            >
              You will be asked a variety of question types that will evaluate
              your knowledge and skills related to the position of Director of
              Sales.
            </div>
          </div>
          <div style="text-align: right">
            <a
              @click="start()"
              style="
                background: #2196f3;
                color: white;
                font-weight: bold;
                padding: 10px 24px;
                border-radius: 4px;
                cursor: pointer;
              "
              >Next</a
            >
          </div>
        </section>
</template>

<script>


export default {
    name: "WelcomeCard",
    props: {
        jobPosition: { type: String, required: true },
        key1: { type: String, required: true },
        key2: { type: String, required: true },
        company: { type: String, required: true },
        key3: { type: String, required: true },
        key4: { type: String, required: true },
        key5: { type: String, required: true },
    },
    methods:{
    start() {
      this.$emit('start');
    },
  }
}

</script>

<style lang="scss" scoped>

.intructions {
  margin-bottom: 20px;
  font-size: 15px;
}

</style>