var render = function render(){var _vm=this,_c=_vm._self._c;return (this.assessementStore.screeners.length > 0)?_c('div',{staticClass:"h-fit p-10 pb-12 bg-white rounded-lg flex flex-col justify-between items-center shadow-[0_0_15px_0_rgba(0,0,0,15%)] w-full"},[_c('div',{staticClass:"w-full"},[_c('div',{staticClass:"flex justify-between"},[_c('h1',{staticClass:"text-[16px] text-slate-700 font-md"},[_vm._v(" "+_vm._s(this.assessementStore.screeners[this.qstStep - 1]?.question)+" "),(
            this.assessementStore.screeners[this.qstStep - 1]?.type ===
            'multiple'
          )?_c('span',{staticClass:"text-[12px] text-slate-500 font-large"},[_vm._v("( Multiple choice )")]):_vm._e()])]),_c('div',{staticClass:"mt-8"},[(
          this.assessementStore.screeners[this.qstStep - 1].type === 'single'
        )?_c('div',{class:{
          'grid grid-cols-2 gap-4': true,
        }},_vm._l((this.assessementStore.screeners[
            this.qstStep - 1
          ]?.options),function(option,index){return _c('div',{key:index,staticClass:"relative radio-container",class:{
            'radio-container': true,
            checked: _vm.selectedOption == option.answer,
          },on:{"click":function($event){return _vm.selectRadio(option)}}},[_c('div',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.selectedOption),expression:"selectedOption"}],staticClass:"custom-radio",attrs:{"type":"radio","id":option.answer,"name":"option"},domProps:{"value":option.answer,"checked":_vm._q(_vm.selectedOption,option.answer)},on:{"change":function($event){_vm.selectedOption=option.answer}}}),_c('label',{staticClass:"mx-5",attrs:{"for":option.answer}},[_vm._v(_vm._s(option.answer))])])])}),0):(
          this.assessementStore.screeners[this.qstStep - 1]?.type ===
          'multiple'
        )?_c('div',{class:{
          'grid grid-cols-2 gap-4': true,
        }},_vm._l((this.assessementStore.screeners[
            this.qstStep - 1
          ].options),function(option,index){return _c('div',{key:index,staticClass:"flex items-center gap-2",class:{
            'radio-container': true,
            checked: _vm.multiOption.includes(option.answer),
          },on:{"click":function($event){return _vm.selectRadio(option)}}},[_c('div',{staticClass:"flex gap-4"},[_c('input',{staticClass:"custom-checkbox",attrs:{"type":"checkbox","id":option.answer,"name":"option"},domProps:{"value":option.answer,"checked":_vm.multiOption.includes(option.answer)}}),_c('label',[_vm._v(_vm._s(option.answer))])])])}),0):_vm._e()])]),_c('div',{staticClass:"w-full flex justify-end"},[(_vm.isBtnLoading)?_c('button',{staticClass:"w-36 h-16 px-5 py-2 ml-auto mt-16 text-white text-base font-bold rounded bg-NeonBlue flex items-center justify-center",class:_vm.disableBtn
          ? 'cursor-not-allowed bg-blue-300'
          : 'cursor-pointer bg-NeonBlue ',attrs:{"disabled":_vm.disableBtn}},[_c('LoaderComponent')],1):_c('button',{staticClass:"w-36 h-16 px-5 py-2 ml-auto mt-16 text-white text-base font-bold rounded bg-NeonBlue",class:_vm.disableBtn
          ? 'cursor-not-allowed bg-blue-300'
          : 'cursor-pointer bg-NeonBlue ',attrs:{"disabled":_vm.disableBtn},on:{"click":_vm.handleClick}},[_vm._v(" "+_vm._s(_vm.buttonText)+" ")])])]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }