<template>
<section  >
    <span style="color: black; text-align: left">{{
      jobPosition
    }}</span>
    <span style="color: grey; margin-bottom: 15px">{{ key2 }}</span>
    <p style="margin-bottom: 15px">
      Hi <strong>{{ key1 }}</strong>
    </p>
    <p class="intructions">
      Congratulations, your application with
      <strong>{{ this.company }}</strong> is progressing!
    </p>
    <p class="intructions">
      We do things a little differently here so in this stage, you'll
      complete the <strong>{{ jobPosition }}</strong> that will give
      you the opportunity to tell us a bit more about yourself and
      showcase your talent.
    </p>
    <p class="intructions">
      In this online stage, you'll be asked a series of interactive
      questions that may require answers in several formats like multiple
      choice, video, and text. This enables us to find out a little more
      about you and see how you approach certain scenarios.
    </p>
    <p class="intructions">
      It's also a great way for you to see what it'll be like working with
      us as the questions are related to the day-to-day tasks you'll be
      doing on the job.
    </p>
    <p class="intructions">
      Please complete this at a time and place that works for you. And
      make sure you have the ability to record video on the device you
      choose to use.
    </p>
    <p class="intructions">
      We look forward to finding out more about you.
    </p>
    <p class="intructions">Good luck!</p>
    <div style="width: 100%; margin-bottom: 40px">
      <div
        style="
          border: 1px solid whitesmoke;
          border-top-left-radius: 3px;
          border-top-right-radius: 3px;
          padding: 18px 35px;
          font-weight: 600;
        "
      >
        <span style="color: #2196f3">
          <font-awesome-icon
            :icon="['far', 'paper-plane']"
            class="mx-2"
            style="color: #2196f3"
          />
          ASSESSMENT SUCCESSFULLY SUBMITTED<br /><span
            style="
              padding-left: 37px;
              color: grey;
              font-weight: unset;
              font-size: 14px;
            "
            >ASSESSMENT RECEIVED BY GO PLATEFORM ON {{ key6 }}</span
          ></span
        >
      </div>
    </div>
  </section>
</template>

<script>


export default {
    name: "WelcomeCardpasstest",
    props: {
        jobPosition: { type: String, required: true },
        key1: { type: String, required: true },
        key2: { type: String, required: true },
        company: { type: String, required: true },
        key3: { type: String, required: true },
        key4: { type: String, required: true },
        key6: { type: String, required: true },
    },
    
}

</script>


<style lang="scss" scoped>

.intructions {
  margin-bottom: 20px;
  font-size: 15px;
}

</style>