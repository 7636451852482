<template>
  <div class="w-full flex flex-col justify-between items-center gap-3">
    <h1
      class="font-bold text-lg text-slate-700 w-full text-left relative after:absolute after:content-('') after:w-full after:h-0.5 after:bg-NeonBlue after:bottom-0 after:left-0 pb-4"
    >
      You have now finished your assessment
    </h1>
    <h2 class="font-semibold text-base text-slate-700 w-full text-left">
      How was your experience?
    </h2>
    <p class="font-light text-sm text-left w-full text-slate-700 mb-4">
      Rating the assessment will help Go-platform improuve subsequent
      assessments
    </p>
    <div class="flex flex-row w-full justify-between items-center">
      <div
        v-for="user in userExpObj"
        :key="user.userExp"
        @click="addUserExp(user.userExp)"
        class="gap-5 p-5 w-1/6 h-fit flex flex-col justify-between items-center rounded border hover:border-NeonBlue text-base font-normal"
        :class="
          userExp === user.userExp
            ? 'border-white bg-NeonBlue text-white'
            : 'border-slate-300 text-slate-700'
        "
      >
        <input
          type="radio"
          name="user_exp"
          :id="user.userExp"
          :value="user.userExp"
          v-model="userExp"
          class="w-4 h-4 relative after:absolute after:content-('') after:w-full after:left-0 after:top-0 after:h-full after:border-2 after:rounded-full"
          :class="
            userExp === user.userExp
              ? ' after:bg-NeonBlue after:border-white after:border-4'
              : 'after:bg-white after:border-slate-300'
          "
        />
        <label
          :for="user.userExp"
          class="flex flex-col justify-between items-center gap-4"
        >
          <font-awesome-icon :icon="['far', user.icon]" class="w-6 h-6" />
          <span>{{ user.userExp }}</span>
        </label>
      </div>
    </div>
    <textarea
      name="userFeedback"
      id="userFeedback"
      v-model="userFeedback"
      @change="addFeedback(userFeedback)"
      placeholder="Please tell us more about your answer ..."
      class="w-full mt-4 font-light text-base h-40 border p-4 border-slate-200 rounded resize-y"
    ></textarea>
    <form
      action=""
      class="w-full flex flex-col gap-6 justify-between items-center bg-gray-100 rounded p-4 mt-4"
    >
      <p
        class="w-full p-2 font-light text-sm text-left bg-sky-200 rounded-sm text-slate-700"
      >
        By collecting your optional background information, we can improve test
        for all candidates by analyqing results anonymously to ensure equal
        opportunities,
        <b class="font-bold">and increase your chances to get hired</b>. Your
        potential employer won't see this information, and we'll protect your
        privacy according to our policy
      </p>
      <div class="w-full flex flex-col justify-between items-center gap-5">
        <div
          v-for="row in form"
          :key="row.selectName"
          class="w-full flex flex-col lg:flex-row justify-between items-center gap-4 relative group"
        >
          <select
            :name="row.selectName"
            :id="row.selectName"
            :placeholder="row.selectPlaceholder"
            v-model="user[row.selectName]"
            @change="addTalent()"
            class="peer w-full lg:w-1/2 p-2 py-3 rounded border text-slate-400 border-slate-300 font-normal text-left text-base"
          >
            <option value="" selected disabled>
              {{ row.selectPlaceholder }}
            </option>
            <option
              v-for="opt in row.selectOptions"
              :key="opt.split(' ').join('')"
              :value="opt"
            >
              {{ opt }}
            </option>
          </select>
          <button
            @click.prevent="
              () => {
                user[row.selectName] = '';
              }
            "
            class="w-6 h-6 text-slate-400 font-light opacity-0 peer-hover:opacity-100 hover:opacity-100 absolute top-1/2 -translate-y-1/2 left-1/2 -translate-x-12"
          >
            <font-awesome-icon :icon="['fas', 'xmark']" />
          </button>
          <input
            type="text"
            :name="row.inputName"
            :id="row.inputName"
            :placeholder="row.inputPlaceholder"
            v-model="user[row.inputName]"
            @change="addTalent()"
            class="peer input w-full lg:w-1/2 p-2 py-3 rounded text-slate-400 border border-slate-300 font-normal text-base"
          />
          <button
            @click.prevent="
              () => {
                user[row.inputName] = '';
              }
            "
            class="peer-[.input]:peer-hover:opacity-100 opacity-1 hover:opacity-100 w-6 h-6 text-slate-400 font-light opacity-0 absolute top-1/2 -translate-y-1/2 right-0 -translate-x-6"
          >
            <font-awesome-icon :icon="['fas', 'xmark']" />
          </button>
        </div>
        <textarea
          name="candidateBio"
          id="candidateBio"
          placeholder="Tell us more about yourself... (max 300 chars)"
          v-model="user.bio"
          class="w-full h-28 resize-y p-2 py-3 rounded border text-slate-400 border-slate-300 font-normal text-left text-base"
          maxlength="300"
        ></textarea>
      </div>
    </form>
  </div>
</template>

<script>
import { useAssessementStore } from "@/store/assessements";
import axios from "axios";
import { countryList } from "@/store/data";
export default {
  name: "NewTalent",
  data() {
    return {
      user: {
        educationLevel: "",
        workExperience: "",
        gender: "",
        country: "",
        countryRaised: "",
        studyField: "",
        experienceYears: "",
        birthYear: "",
        ethnicity: "",
        firstLanguage: "",
        bio: "",
      },
      talentObj: {},
      jobsList: [],
      userExp: "",
      userFeedback: "",
      userExpObj: [
        { userExp: "Terrible", icon: "face-tired" },
        { userExp: "Bad", icon: "face-frown" },
        { userExp: "OK", icon: "face-meh" },
        { userExp: "Good", icon: "face-smile" },
        { userExp: "Great", icon: "face-laugh-beam" },
      ],
      form: [
        {
          selectName: "educationLevel",
          inputName: "studyField",
          selectPlaceholder: "Highest Education Level",
          selectOptions: [
            "Some high school",
            "High school Diploma / GED",
            "Some College",
            "Associate Degree",
            "Bachelor's Degree",
            "Master's Degree or Higher",
          ],
          inputPlaceholder: "What did you study?",
        },
        {
          selectName: "workExperience",
          inputName: "experienceYears",
          selectPlaceholder: "Most relevant work experience",
          selectOptions: [],
          inputPlaceholder: "Years of experience in role",
        },
        {
          selectName: "gender",
          inputName: "birthYear",
          selectPlaceholder: "Gender",
          selectOptions: [
            "Woman",
            "Man",
            "Nonbinar/Other",
            "Prefer not to answer",
          ],
          inputPlaceholder: "What year were you born?",
        },
        {
          selectName: "country",
          inputName: "ethnicity",
          selectPlaceholder: "Country/Region of Residence",
          selectOptions: ["Afghanistan", "Albania", "Algeria"],
          inputPlaceholder: "Ethnicity",
        },
        {
          selectName: "countryRaised",
          inputName: "firstLanguage",
          selectPlaceholder: "In what country you were raised",
          selectOptions: ["Afghanistan", "Albania", "Algeria"],
          inputPlaceholder: "What is your first language",
        },
      ],
    };
  },
  setup() {
    const Store = useAssessementStore();
    return { Store };
  },
  methods: {
    addUserExp(exp) {
      this.userExp = exp;
      this.user = { ...this.user, satisfaction: exp };
    },
    addFeedback(feedback) {
      this.user = { ...this.user, feedback: feedback };
    },
    addTalent() {
      this.Store.fillTalent(this.user);
    },
  },
  mounted() {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: "https://server.go-platform.com/jobPositions/all",
      headers: {},
      withCredentials: true,
    };
    axios
      .request(config)
      .then((response) => {
        this.jobsList = response.data.data.map((job) => job.title);
        this.form[1].selectOptions = [...this.jobsList];
        this.form[3].selectOptions = [...countryList];
        this.form[4].selectOptions = [...countryList];
        // console.log({ jobPositions: this.jobsList });
      })
      .catch((error) => {
        console.log(error);
      });
  },
};
</script>

<style lang="scss" scoped></style>
