<template>
  <div>
    <div id="app">
      <NavBar />
      <AssessementTest @logged_candidate="setLogged()" />
      <!-- <ShareResults /> -->
    </div>
  </div>
</template>

<script>
import NavBar from "./components/navBar.vue";
import AssessementTest from "./components/assessementTest.vue";
import { useAssessementStore } from "./store/assessements";
// import ShareResults from "./components/ShareResults.vue";

export default {
  name: "App",
  components: {
    NavBar,
    AssessementTest,
    // ShareResults,
  },
  data() {
    return {
      initials: "",
      full_name: "",
      logged: false,
    };
  },
  setup() {
    const assessementStore = useAssessementStore();
    return { assessementStore };
  },
  methods: {
    handleKeyDown(event) {
      // Check if Ctrl key is pressed and 'C' key is pressed
      if (event.ctrlKey && event.key === "c") {
        // Handle Ctrl+C here
        console.log("Ctrl+C pressed");

        // Prevent the default browser behavior (copying text)
        event.preventDefault();
      }
    },
    setLogged(value) {
      this.initials = value.initials;
      this.full_name = value.full_name;
      this.logged = value.logged;
    },
    requestCameraAccess() {
      navigator.mediaDevices
        .getUserMedia({ video: true })
        .then(function () {
          // Camera access granted, do something with the stream
        })
        .catch(function (error) {
          // Handle errors, e.g., if the user denies camera access
          console.error("Error accessing camera:", error);
        });
    },
  },
  mounted() {
    this.requestCameraAccess();
  },
};
</script>

<style>
::-webkit-scrollbar {
  width: 0.75rem;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: #bbb;
  border-radius: 5px;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}
</style>
