<template>
  <section
    :class="addedClass"
    class="h-fit p-10 pb-12 bg-white rounded-lg flex flex-col justify-between items-center shadow-[0_0_15px_0_rgba(0,0,0,15%)]"
  >
    <div :class="'h-full w-full ' + insideDivClass">
      <slot></slot>
    </div>
    <button
      v-if="isBtnLoading"
      @click="handleClick"
      class="w-36 h-16 px-5 py-2 ml-auto mt-16 text-white text-base font-bold rounded bg-NeonBlue flex items-center justify-center"
      :class="
        disableBtn
          ? 'cursor-not-allowed bg-blue-300'
          : 'cursor-pointer bg-NeonBlue '
      "
      :disabled="disableBtn"
    >
      <LoaderComponent />
    </button>
    <button
      v-else
      @click="handleClick"
      class="w-36 h-16 px-5 py-2 ml-auto mt-16 text-white text-base font-bold rounded bg-NeonBlue"
      :class="
        disableBtn
          ? 'cursor-not-allowed bg-blue-300'
          : 'cursor-pointer bg-NeonBlue '
      "
      :disabled="disableBtn"
    >
      {{ buttonText }}
    </button>
  </section>
</template>

<script>
import LoaderComponent from "./LoaderComponent.vue";
import { useAssessementStore } from "@/store/assessements";


export default {
  name: "TestBox",
  components: {
    LoaderComponent,
  },
  props: {
    buttonText: { type: String },
    handleClick: { type: Function },
    addedClass: { type: String },
    insideDivClass: { type: String },
    disableBtn: { type: Boolean, default: false },
    isBtnLoading: { type: Boolean, default: false },
  },
  setup() {
    const assessementStore = useAssessementStore();
    return { assessementStore };
  },
};
</script>

<style lang="scss" scoped></style>
