<template>
    <div class="flex flex-col justify-between gap-6 items-center text-sm font-normal px-5">
        <h1 class="w-full font-semibold text-2xl relative flex after:absolute after:content-('') after:w-full after:h-0.5 pb-4 after:bg-NeonBlue after:bottom-0 after:left-0">Assessment setup</h1>
        <p class="w-full">
            To make sure that everyone is fairly treated, we might need to adjust your assessment setup. You can help us do that by answering the following questions:
        </p>
        <div class="w-full flex flex-col gap-4">
            <p>Are you fluent in English</p>
            <form class="w-full flex flex-row justify-between gap-4">
                <div @click="store.SelectOption('english', 'fluent')" class="w-1/2 flex flex-row justify-start items-center rounded border border-gray-200 p-3 gap-2 py-4 hover:border-NeonBlue" :class=" store.english=='fluent'?'bg-NeonBlue text-white':'bg-white text-neutral-800'">
                    <input type="radio" name="QstOption" id="QstOption" :checked="store.english=='fluent'" class="bg-NeonBlue">
                    <label class="font-normal text-sm ">Yes, I am fluent</label>
                </div>
                <div @click="store.SelectOption('english', 'notFluent')" class="w-1/2 flex flex-row justify-start items-center rounded border border-gray-200 p-3 gap-2 py-4 hover:border-NeonBlue"  :class="store.english=='notFluent'?'bg-NeonBlue text-white':'bg-white text-neutral-800'">
                    <input type="radio" name="QstOption" id="QstOption" :checked="store.english=='notFluent'" class="bg-NeonBlue">
                    <label class="font-normal text-sm ">No, I am not fluent</label>
                </div>
            </form>
        </div>
        <p>
            Persons with disabilities or conditions that affect memory or concentration abilities, such as ADHD, dyslexia or autism, may require assessment change(s) in order to proceed.  Do you require additional support to complete our online assessment?
        </p>
        <div class="w-full flex flex-col gap-4 relative after:absolute after:content-('') after:w-full after:h-0.5 after:bg-gray-200 after:-bottom-6 after:left-0 pb-4">
            <p>If you don't consider your condition to be a hindrance when taking a test, you can answer ”No” to proceed to the assessment.</p>
            <form class="w-full flex flex-row justify-between gap-4">
                <div @click="store.SelectOption('disability', 'yes')" class="w-1/2 flex flex-row justify-start items-center rounded border border-gray-200 p-3 gap-2 py-4 hover:border-NeonBlue" :class="store.disability=='yes'?'bg-NeonBlue text-white':'bg-white text-neutral-800'">
                    <input type="radio" name="QstOption" id="QstOption" :checked="store.disability=='yes'" class="bg-NeonBlue">
                    <label class="font-normal text-sm ">Yes, I do</label>
                </div>
                <div @click="store.SelectOption('disability', 'no')" class="w-1/2 flex flex-row justify-start items-center rounded border border-gray-200 p-3 gap-2 py-4 hover:border-NeonBlue" :class="store.disability=='no'?'bg-NeonBlue text-white':'bg-white text-neutral-800'">
                    <input type="radio" name="QstOption" id="QstOption" :checked="store.disability=='no'" class="bg-NeonBlue">
                    <label class="font-normal text-sm ">No, I don't</label>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
import { useAssessementStore } from '@/store/assessements';
export default {
    name:"AssessmentSetup",
    data(){
        return{ 

        }
    },
    setup () {
        const store = useAssessementStore()
        return {
            store
        }
    },
    methods:{
    },
}
</script>

<style lang="scss" scoped>
input[type='radio']{
    position: relative;
}
input[type='radio']:checked::after {
    content: "";
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background: #2196f3;
    border: 3px solid white;
    position: absolute;
}
</style>