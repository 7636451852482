<template>
    <div @click="hanldeClick"
        class='w-11/12 flex flex-row h-1/4 max-h-1/4 justify-start items-center rounded-lg border border-gray-200  gap-2 px-4 py-6 hover:border-NeonBlue'
        :class="selected ? 'bg-NeonBlue text-white' : 'bg-white text-neutral-800'">
        <input type="radio" name="QstOption" id="QstOption" :checked="selected" class="bg-NeonBlue">
        <label class="font-normal text-sm" v-html="formatOption(formatBold(capitalizeFirstLetter(OptionText)))">
        </label>
    </div>
</template>

<script>
import { useAssessementStore } from "@/store/assessements";
export default {
    name: "QstOption",
    props: ['optionKey', 'OptionText', 'SelectOption', 'selected'],
    setup() {
        const assessementStore = useAssessementStore();
        return {
            assessementStore
        }
    },
    methods: {
        formatOption(text) {
            const points = text.split(". ").map((point) => point.trim());
            const formattedText = points.join(".<br>");

            return formattedText;
        },
        formatBold(text) {
            if (!text) return '';
            // Replace **word** with <strong>word</strong>
            return text.replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>');
        },
        capitalizeFirstLetter(text) {
            if (!text) return '';
            return text.charAt(0).toUpperCase() + text.slice(1);
        },
        hanldeClick() {
            this.$emit('answered', this.optionKey)
            this.SelectOption(this.optionKey)
        }
    }
}
</script>

<style lang="scss" scoped>
input[type='radio'] {
    position: relative;
}

input[type='radio']:checked::after {
    content: "";
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background: #2196f3;
    border: 3px solid white;
    position: absolute;
}
</style>